<template>
  <div id="app" :class="{ loaded: loaded }">
    <div id="scrolltop" data-scroll-section></div>
    <LoadingPage :class="{ hide: hideLoading }" v-if="!loaded" />
    <PageTransition :class="{ enter: pageEnter }" :show="showPageTrans" />
    <router-view
      @pageTrans="transitionPage"
      :class="{ loaded: loaded }"
    ></router-view>
  </div>
</template>

<script>
//Loading page
import LoadingPage from "@/components/util/LoadingPage.vue";
//Page trans
import PageTransition from "@/components/util/PageTransition.vue";

// //Mixins
import scrollInit from "@/mixins/scrollInit";

export default {
  name: "App",
  mixins: [scrollInit],
  components: {
    LoadingPage,
    PageTransition,
  },
  data() {
    return {
      hideLoading: false,
      loaded: false,
      isScrolling: false,
      showPageTrans: false,
      pageEnter: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.hideLoading = true;
      setTimeout(() => {
        this.loaded = true;
        this.initLms();
      }, 500);
    }, 100);
  },
  methods: {
    transitionPage() {
      this.showPageTrans = true;
      setTimeout(() => {
        this.pageEnter = true;
      }, 1000);
      setTimeout(() => {
        this.showPageTrans = false;
        this.pageEnter = false;
      }, 2000);
    },
  },
  watch: {
    $route(to, from) {
      if (to || from) {
        let target = document.getElementById('scrolltop')
        this.lms.scrollTo(target);
      }
    },
  },
};
</script>

<style lang="scss">
::selection {
  background-color: $green;
  color: $white;
}

#app {
  position: relative;
  cursor: none;
  &.loaded {
    cursor: default;
  }
  .app-inner {
    opacity: 0;
    transition: opacity 0.5s 0.25s ease;
    &.loaded {
      opacity: 1;
    }
    .logo {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99;
    }
    .kinesis-container {
      perspective: $break-l;
      width: 100%;
      height: 100%;
      @media (max-width: $break-l) {
        perspective: 100%;
      }
    }
    main {
      position: relative;
      height: 100%;
      width: 100%;
      section.with-cards-and-img {
        position: relative;
        height: 100%;
        width: 100%;
        padding: 12rem 0;
        z-index: 2;
        .cbd {
          color: $green;
          font-weight: 700;
        }
        > .inner {
          @include Flex;
          height: 100%;
          .h-wrap {
            width: 100%;
            opacity: 0;
            transition: opacity 0.5s 0.15s ease;
            z-index: 3;
            &.is-inview {
              opacity: 1;
            }
            h1 {
              color: $green;
              font-size: 6vw;
              text-align: center;
            }
          }
          .img-container {
            position: absolute;
            top: 15%;
            right: 6%;
            z-index: 1;
            &.img2 {
              top: unset;
              bottom: 5%;
              right: unset;
              left: 6%;
            }
            .img {
              transition: opacity 0.5s ease;
              opacity: 0;
              img {
                filter: blur(50px);
                transition: filter 1s 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
                will-change: filter;
              }
              &.is-inview {
                transition: opacity 0.5s 0.5s ease;
                opacity: 1;
                img {
                  filter: blur(0px);
                }
              }
            }
          }
          .content {
            position: relative;
            height: 100%;
            width: 100%;
            max-width: $break-l;
            margin: 0 auto;
            z-index: 2;
            .bg {
              @include FlexCentered;
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              svg {
                transform: scale3d(0, 0, 0);
                opacity: 0;
                transition: transform 0s 0.5s ease, opacity 0.5s ease;
              }
              &.is-inview {
                svg {
                  opacity: 1;
                  transform: scale3d(1, 1, 1);
                  transition: transform 0.5s 0.5s
                      cubic-bezier(0.25, 0.46, 0.45, 0.94),
                    opacity 0.5s;
                }
              }
            }

            .card {
              position: relative;
              width: 100%;
              max-width: 50%;
              margin: 4rem auto 8rem 0;
              z-index: 2;
              @media (min-width: $break-l) {
                left: -10%;
              }
              &.card2 {
                margin: 8rem 0 4rem auto;
                @media (min-width: $break-l) {
                  right: -10%;
                  left: unset;
                }
              }
              .inner {
                position: relative;
                padding: 3rem;
                background-color: #fff;
                box-shadow: $box-shad;
                border-radius: $border-rad;
                opacity: 0;
                transform: translateY(40px);
                transition: transform 0.5s 0.25s ease, opacity 0.25s 0.25s ease;
                h4 {
                  color: $black;
                }
                p {
                  margin: 0 0 2rem 0;
                  &:last-of-type {
                    margin: 0;
                  }
                }
              }
              &.is-inview {
                .inner {
                  opacity: 1;
                  transform: translateY(0);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
