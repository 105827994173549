<template>
  <div class="app-inner">
    <div class="logo">
      <Logo />
    </div>
    <Nav @scrollTarget="scrollToTarget" />
    <kinesis-container class="kinesis-container">
      <main>
        <Intro id="intro" data-scroll-section />
        <SectionOne
          id="section-one"
          class="with-cards-and-img"
          data-scroll-section
        />
        <SeeMore link="/what-is-cbd" data-scroll-section>
          See More
        </SeeMore>
        <SectionTwo
          id="section-two"
          class="with-cards-and-img"
          data-scroll-section
        />
        <SeeMore link="/what-does-cbd-do" data-scroll-section>
          See More
        </SeeMore>
        <SectionThree id="section-three" data-scroll-section />
        <SeeMore link="/dr-sarah" data-scroll-section>
          See More
        </SeeMore>
        <!--<SectionFour id="section-four" data-scroll-section />-->
      </main>
    </kinesis-container>
    <ProductsPopup />

    <canvas></canvas>
    <div id="debug-value"></div>
  </div>
</template>

<script>
// UI
import Logo from "@/assets/logo/Logo.vue";
import Nav from "@/components/Nav.vue";
import ProductsPopup from "@/components/ProductsPopup.vue";
import Intro from "@/components/Intro.vue";
import SectionOne from "@/components/SectionOne.vue";
import SectionTwo from "@/components/SectionTwo.vue";
import SectionThree from "@/components/SectionThree.vue";
//import SectionFour from "@/components/SectionFour.vue";
import SeeMore from "@/components/util/SeeMore.vue";

import pageTrans from "@/mixins/pagetrans";

export default {
  name: "home",
  mixins: [pageTrans],
  components: {
    Nav,
    Logo,
    ProductsPopup,
    Intro,
    SectionOne,
    SectionTwo,
    SectionThree,
    //SectionFour,
    SeeMore,
  },
  data() {
    return {
      hideLoading: false,
      loaded: false,
      isScrolling: false,
      showSectionOneModal: false,
      showSectionTwoModal: false,
      showSectionThreeModal: false,
      showSectionFourModal: false,
    };
  },
  mounted() {},
  methods: {
    scrollToTarget(target) {
      this.lms.scrollTo("#" + target);
    },
  },
};
</script>
