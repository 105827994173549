<template>
  <div class="see-more">
    <div class="inner" data-scroll data-scroll-speed="4">
      <router-link :to="link">
        <div class="see-more-btn mag-btn">
          <div class="text">
            <slot></slot>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  props: ["link"],
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss">
.see-more {
  padding: 5rem 0;
  width: 100%;
  z-index: 9;
  > .inner {
    @include FlexCentered;
    .see-more-btn {
      @include FlexCentered;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      background-color: $secondary;
      cursor: pointer;
      .text {
        font-size: 1.2rem;
        color: $white;
      }
    }
  }
}
</style>
