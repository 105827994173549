<template>
  <div class="close" @click="close">
    <div class="inner"></div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  right: -1rem;
  top: -1rem;
  .inner {
    @include FlexCentered();
    height: 48px;
    width: 48px;
    background-color: $secondary;
    border-radius: 50%;
    box-shadow:$el-shad;
    cursor: pointer;
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 1px);
      left: calc(50% - 6px);
      width: 12px;
      height: 2px;
      background: #fff;
      transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &:before {
      transform: translateZ(0) rotate(45deg);
    }
    &:after {
      transform: translateZ(0) rotate(-45deg);
    }
    &:hover {
      &:before,
      &:after {
        transform: none;
      }
    }
  }
}
</style>
