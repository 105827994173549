<template>
  <svg
    id="loading-cursor"
    width="100%"
    height="100%"
    viewBox="0 0 200 200"
    xmlns="http://www.w3.org/2000/svg"
    style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;"
  >
    <g transform="matrix(0.980053,0,0,0.980053,2.41256,1.40012)">
      <g transform="matrix(14.3318,27.0482,-27.0482,14.3318,6.78481,138.134)">
        <path
          d="M0.09,0L0.09,-0.693L0.134,-0.693L0.134,0L0.09,0ZM0.09,0L0.09,-0.043L0.525,-0.043L0.525,0L0.09,0Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(22.88,20.335,-20.335,22.88,26.5267,168.539)">
        <path
          d="M0.293,0.01C0.13,0.01 0.049,-0.107 0.049,-0.342C0.049,-0.583 0.13,-0.703 0.293,-0.703C0.456,-0.703 0.537,-0.583 0.537,-0.342C0.537,-0.107 0.456,0.01 0.293,0.01ZM0.293,-0.029C0.426,-0.029 0.492,-0.133 0.492,-0.342C0.492,-0.553 0.426,-0.659 0.293,-0.659C0.16,-0.659 0.094,-0.553 0.094,-0.342C0.094,-0.133 0.16,-0.029 0.293,-0.029Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(28.6097,10.8854,-10.8854,28.6097,55.7189,190.046)">
        <path
          d="M0.034,0L0.262,-0.693L0.324,-0.693L0.552,0L0.508,0L0.295,-0.656L0.291,-0.656L0.078,0L0.034,0ZM0.115,-0.194L0.115,-0.237L0.466,-0.237L0.466,-0.194L0.115,-0.194Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(30.6095,-0.254814,0.254814,30.6095,90.6409,199.813)">
        <path
          d="M0.127,0L0.127,-0.043L0.246,-0.043C0.333,-0.043 0.399,-0.067 0.442,-0.116C0.485,-0.165 0.507,-0.239 0.507,-0.337C0.507,-0.442 0.485,-0.52 0.442,-0.572C0.399,-0.624 0.333,-0.65 0.246,-0.65L0.129,-0.65L0.117,-0.693L0.246,-0.693C0.45,-0.693 0.552,-0.575 0.552,-0.337C0.552,-0.112 0.45,0 0.246,0L0.127,0ZM0.09,0L0.09,-0.693L0.134,-0.693L0.134,0L0.09,0Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(28.6097,-10.8854,10.8854,28.6097,126.742,196.424)">
        <path
          d="M0.271,0L0.271,-0.693L0.314,-0.693L0.314,0L0.271,0ZM0.094,0L0.094,-0.043L0.492,-0.043L0.492,0L0.094,0ZM0.094,-0.65L0.094,-0.693L0.492,-0.693L0.492,-0.65L0.094,-0.65Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(22.88,-20.335,20.335,22.88,159.292,180.454)">
        <path
          d="M0.43,0L0.132,-0.65L0.105,-0.65L0.105,-0.693L0.156,-0.693L0.455,-0.043L0.475,-0.043L0.475,0L0.43,0ZM0.084,0L0.084,-0.693L0.128,-0.693L0.128,0L0.084,0ZM0.458,0L0.458,-0.693L0.502,-0.693L0.502,0L0.458,0Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(14.1469,-27.1454,27.1454,14.1469,184.103,153.996)">
        <path
          d="M0.353,0.01C0.257,0.01 0.184,-0.021 0.132,-0.084C0.08,-0.146 0.054,-0.234 0.054,-0.348C0.054,-0.461 0.079,-0.548 0.131,-0.61C0.182,-0.672 0.255,-0.703 0.349,-0.703C0.393,-0.703 0.432,-0.697 0.467,-0.683C0.501,-0.67 0.528,-0.652 0.546,-0.627L0.513,-0.594C0.494,-0.615 0.471,-0.631 0.443,-0.642C0.416,-0.654 0.386,-0.659 0.354,-0.659C0.273,-0.659 0.21,-0.632 0.166,-0.579C0.121,-0.525 0.099,-0.45 0.099,-0.353C0.099,-0.252 0.122,-0.173 0.168,-0.118C0.215,-0.062 0.28,-0.034 0.364,-0.034C0.395,-0.034 0.421,-0.037 0.445,-0.043C0.468,-0.049 0.487,-0.054 0.502,-0.059L0.53,-0.02C0.517,-0.015 0.494,-0.009 0.462,-0.002C0.43,0.006 0.394,0.01 0.353,0.01ZM0.486,-0.02L0.486,-0.322L0.53,-0.322L0.53,-0.02L0.486,-0.02ZM0.308,-0.279L0.308,-0.322L0.527,-0.322L0.527,-0.279L0.308,-0.279Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(14.3318,-27.0482,27.0482,14.3318,27.071,72.0949)">
        <path
          d="M0.09,0L0.09,-0.693L0.134,-0.693L0.134,0L0.09,0ZM0.09,0L0.09,-0.043L0.525,-0.043L0.525,0L0.09,0Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(22.88,-20.335,20.335,22.88,41.778,48.1003)">
        <path
          d="M0.293,0.01C0.13,0.01 0.049,-0.107 0.049,-0.342C0.049,-0.583 0.13,-0.703 0.293,-0.703C0.456,-0.703 0.537,-0.583 0.537,-0.342C0.537,-0.107 0.456,0.01 0.293,0.01ZM0.293,-0.029C0.426,-0.029 0.492,-0.133 0.492,-0.342C0.492,-0.553 0.426,-0.659 0.293,-0.659C0.16,-0.659 0.094,-0.553 0.094,-0.342C0.094,-0.133 0.16,-0.029 0.293,-0.029Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(28.6097,-10.8854,10.8854,28.6097,63.8829,30.891)">
        <path
          d="M0.034,0L0.262,-0.693L0.324,-0.693L0.552,0L0.508,0L0.295,-0.656L0.291,-0.656L0.078,0L0.034,0ZM0.115,-0.194L0.115,-0.237L0.466,-0.237L0.466,-0.194L0.115,-0.194Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(30.6095,0.254814,-0.254814,30.6095,90.4498,22.6242)">
        <path
          d="M0.127,0L0.127,-0.043L0.246,-0.043C0.333,-0.043 0.399,-0.067 0.442,-0.116C0.485,-0.165 0.507,-0.239 0.507,-0.337C0.507,-0.442 0.485,-0.52 0.442,-0.572C0.399,-0.624 0.333,-0.65 0.246,-0.65L0.129,-0.65L0.117,-0.693L0.246,-0.693C0.45,-0.693 0.552,-0.575 0.552,-0.337C0.552,-0.112 0.45,0 0.246,0L0.127,0ZM0.09,0L0.09,-0.693L0.134,-0.693L0.134,0L0.09,0Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(28.6097,10.8854,-10.8854,28.6097,118.578,24.5129)">
        <path
          d="M0.271,0L0.271,-0.693L0.314,-0.693L0.314,0L0.271,0ZM0.094,0L0.094,-0.043L0.492,-0.043L0.492,0L0.094,0ZM0.094,-0.65L0.094,-0.693L0.492,-0.693L0.492,-0.65L0.094,-0.65Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(22.88,20.335,-20.335,22.88,144.041,36.1853)">
        <path
          d="M0.43,0L0.132,-0.65L0.105,-0.65L0.105,-0.693L0.156,-0.693L0.455,-0.043L0.475,-0.043L0.475,0L0.43,0ZM0.084,0L0.084,-0.693L0.128,-0.693L0.128,0L0.084,0ZM0.458,0L0.458,-0.693L0.502,-0.693L0.502,0L0.458,0Z"
          style="fill-rule:nonzero;"
        />
      </g>
      <g transform="matrix(14.1469,27.1454,-27.1454,14.1469,163.744,56.0935)">
        <path
          d="M0.353,0.01C0.257,0.01 0.184,-0.021 0.132,-0.084C0.08,-0.146 0.054,-0.234 0.054,-0.348C0.054,-0.461 0.079,-0.548 0.131,-0.61C0.182,-0.672 0.255,-0.703 0.349,-0.703C0.393,-0.703 0.432,-0.697 0.467,-0.683C0.501,-0.67 0.528,-0.652 0.546,-0.627L0.513,-0.594C0.494,-0.615 0.471,-0.631 0.443,-0.642C0.416,-0.654 0.386,-0.659 0.354,-0.659C0.273,-0.659 0.21,-0.632 0.166,-0.579C0.121,-0.525 0.099,-0.45 0.099,-0.353C0.099,-0.252 0.122,-0.173 0.168,-0.118C0.215,-0.062 0.28,-0.034 0.364,-0.034C0.395,-0.034 0.421,-0.037 0.445,-0.043C0.468,-0.049 0.487,-0.054 0.502,-0.059L0.53,-0.02C0.517,-0.015 0.494,-0.009 0.462,-0.002C0.43,0.006 0.394,0.01 0.353,0.01ZM0.486,-0.02L0.486,-0.322L0.53,-0.322L0.53,-0.02L0.486,-0.02ZM0.308,-0.279L0.308,-0.322L0.527,-0.322L0.527,-0.279L0.308,-0.279Z"
          style="fill-rule:nonzero;"
        />
      </g>
    </g>
    <g transform="matrix(1.53922,0,0,1.53922,-16.6863,-59.9058)">
      <circle cx="19.936" cy="104.44" r="3.248" />
    </g>
    <g transform="matrix(1.53922,0,0,1.53922,153.314,-59.9058)">
      <circle cx="19.936" cy="104.44" r="3.248" />
    </g>
  </svg>
</template>

<script>
export default {
  mounted() {
    this.handleMouse();
  },
  methods: {
    handleMouse() {
      let cur = document.getElementById("loading-cursor");
      if (window.innerWidth < 1200) {
          cur.classList.add('center');
      } else {
        let bd = document.body;
        bd.addEventListener("mousemove", function(n) {
          (cur.style.left = n.clientX + "px"),
            (cur.style.top = n.clientY + "px");
        });
      }
    },
  },
};
</script>

<style lang="scss">
#loading-cursor {
  overflow: hidden;
  position: fixed;
  top: 100px;
  left: 100px;
  width: 200px;
  height: 200px;
  animation: rotate 5s linear infinite;
  &.center {
      position: relative;
      top:0;
      left:0;
  }
}

</style>
