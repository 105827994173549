<template>
  <section>
    <div class="inner" data-scroll>
      <div
        class="bg"
        data-scroll
        data-scroll-speed="1"
        style="background-image:url('/img/herobg.jpg');"
      ></div>
      <div class="content">
        <div class="inner">
          <div class="header" data-scroll data-scroll-speed="-3">
            <div class="h-wrap main">
              <div class="reveal intro">
                <h1>Guide to</h1>
              </div>
              <div class="reveal intro">
                <h1><span>CBD</span> Oil</h1>
              </div>
            </div>
            <div class="h-wrap sub">
              <div class="reveal intro">
                <h5>
                  CBD (cannabidiol) oil has exploded in popularity in the past
                  few years - but what is it and is it a supplement for you?
                  Here's everything you need to know about Healthspan's CBD oil
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
section {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: 1;
  > .inner {
    overflow: hidden;
    .bg {
      @include FlexCentered;
      position: absolute;
      height: 100%;
      width: 100%;
      opacity: 0;
      z-index: -1;
      overflow: hidden;
      transition: opacity 0.5s ease;
    }
    &.is-inview {
      .bg {
        opacity: 1;
      }
    }
    .content {
      @include FlexCentered();
      height: 100%;
      min-height: 100vh;
      width: 100%;
      max-width: $break-l;
      border-left: 1px solid $white;
      border-right: 1px solid $white;
      margin: auto;
      > .inner {
        position: relative;
        margin: 0;
        height: 100%;
        transform: scale3d(1, 1, 1);
        transition: transform 0.5s ease;
        .header {
          display: block;
          margin: 0;
          width: 100%;
          min-width: $break-l;
          padding: 0 2rem;
          opacity: 0;
          transition: opacity 0.5s ease;
          @media (max-width: $break-l) {
            min-width: 100%;
          }
          &.is-inview {
            opacity: 1;
          }
          .main {
            margin: 0 0 1rem 0;
            .reveal {
              margin: 0 auto 0 0;
              h1 {
                position: relative;
                margin:0;
                span {
                  color: $green;
                }
                @media (max-width: $break-l) {
                  left: 0;
                }
              }
            }
          }
          .sub {
            .reveal {
              margin: 0 0 0 auto;
              h5 {
                position: relative;
                max-width: $break-m;
                text-align: right;
                @media (max-width: $break-l) {
                  left: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
