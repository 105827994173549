<template>
  <div class="card">
    <div class="img">
      <img :src="img" alt="CBD Oil" />
    </div>
    <div class="content">
      <div class="title">
        <h5>{{ title }}</h5>
      </div>
      <div class="text">
        {{ text }}
      </div>
    </div>
    <div class="cta">
      <div class="btn">
        <a :href="url">
          Shop now
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["img", "title", "text", "url"],
};
</script>

<style lang="scss" scoped>
.card {
  width: 300px;
  height: 550px;
  padding: 0 0 69px 0;
  border-radius: $border-rad;
  background-color: #fff;
  overflow: hidden;
  * {
    user-select: none;
  }
  .img {
    img {
      width: 100%;
      height: auto;
      max-height: 300px;
    }
  }
  .content {
    padding: 1rem;
    .title {
      h5 {
        color: $primary;
        margin: 0 0 0.5rem 0;
      }
    }
    .text {
      margin: 0 0 1rem 0;
    }
  }
  .cta {
    position: absolute;
    bottom: 0;
    left: 0;
    @include FlexCentered;
    width: 100%;
    height: 69px;
    .btn {
      width: 100%;
      border-radius: 0 0 $border-rad $border-rad;
      padding: 1rem 9px;
    }
  }
}
</style>
