<template>
  <div class="min" @click="min">
    <div class="inner"></div>
  </div>
</template>

<script>
export default {
  methods: {
    min() {
      this.$emit("min");
    },
  },
};
</script>

<style lang="scss" scoped>
.min {
  position: absolute;
  right: -1rem;
  top: -1rem;
  z-index: 999;
  .inner {
    @include FlexCentered();
    height: 32px;
    width: 32px;
    background-color: $secondary;
    border-radius: 50%;
    box-shadow: $el-shad;
    transform: rotate(90deg);
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      top: calc(50% - 8px);
      left: calc(50% - 8px);
      width: 16px;
      height: 16px;
      background-image: url("/img/down-arrow-white.png");
      background-size:12px;
      background-position: center;
      background-repeat: no-repeat;
      transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    &:hover {
      &:before,
      &:after {
        transform: translateX(2px);
      }
    }
  }
}
</style>
