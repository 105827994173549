<template>
  <nav :class="{ hide: startHide }">
    <Min @min="minNav" />
    <ul class="nav-links">
      <li class="link">
        <div class="fake-hash" @click="scrollTo('section-one')">
          What is CBD?
        </div>
      </li>
      <li class="link">
        <div class="fake-hash" @click="scrollTo('section-two')">
          What does it do?
        </div>
      </li>
      <li class="link">
        <div class="fake-hash" @click="scrollTo('section-three')">
          Dr. Sarah on CBD balm
        </div>
      </li>
      <!-- <li class="link">
        <div class="fake-hash" @click="scrollTo('section-four')">
          Section Four
        </div>
      </li> -->
    </ul>
  </nav>
</template>

<script>
import Min from "@/components/util/Min.vue";

export default {
  data() {
    return {
      startHide: false,
    };
  },
  components: {
    Min,
  },
  methods: {
    scrollTo(hash) {
      this.$emit("scrollTarget", hash);
    },
    minNav() {
      this.startHide = !this.startHide;
    },
  },
};
</script>

<style lang="scss">
nav {
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  padding: 1rem;
  border-radius: $border-rad;
  background-color: #fff;
  box-shadow: $box-shad;
  transform: translateY(0);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 99;
  &.hide {
    transform: translateY(100%);
    transition: transform 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    .min {
      .inner {
        transform: rotate(270deg);
      }
    }
  }
  @media (max-width:$break-l) {
    &.hide {
      transform: translate(0);
    }
    .min {
      display: none;
    }
  }
  .nav-links {
    text-align: right;
    .link {
      margin: 0.5rem 0 0;
      &:first-of-type {
        margin: 0;
      }
      .fake-hash {
        cursor: pointer;
      }
    }
  }
  @media (max-width: $break-l) {
    right: unset;
    left: 0;
    bottom: 0;
    width: 100%;
    border-radius: $border-rad $border-rad 0 0;
    .nav-links {
      @include FlexCentered;
      .link {
        margin: 0;
        text-align: center;
        flex-grow: 1;
      }
    }
  }
}
</style>
