<template>
  <section>
    <div class="inner">
      <div
        class="h-wrap"
        data-scroll-offset="10%"
        data-scroll
        data-scroll-speed="2"
      >
        <div class="reveal">
          <h1>What is CBD?</h1>
        </div>
      </div>

      <div class="img-container img1">
        <div class="img" data-scroll data-scroll-speed="-1">
            <img class="plane" src="/img/drops.jpg" alt="CBD Oil" />
        </div>
      </div>

      <div class="img-container img2">
        <div class="img" data-scroll data-scroll-speed="1">
            <img src="/img/cream.jpg" alt="CBD Cream" />
        </div>
      </div>

      <div class="content">

          <div
            class="card card1"
            data-scroll-offset="10%"
            data-scroll
            data-scroll-speed="2"
          >
            <div class="inner">
              <h4>
                When it comes to <span class="cbd">CBD</span> the first thing
                you'll want to know is where it sits in relation to the words
                'cannabis', 'hemp' and 'marijuana'.
              </h4>
              <p>
                The hemp plant and the marijuana plant are both types of
                cannabis. What makes them completely different is their
                ingredients – specifically <span class="cbd">CBD</span> and
                <span class="cbd">THC</span>. The ingredient
                <span class="cbd">CBD</span> (otherwise known as cannabidiol) is
                a natural extract of the hemp plant that's non-intoxicating and
                void of any psychoactive properties. It's also the ingredient
                that's been widely researched in the States for its health
                benefits. The ingredient THC, present in significant quantities
                in the marijuana plant, is responsible for the so-called 'high'
                associated with cannabis.
              </p>
            </div>
          </div>

          <div
            class="card card2"
            data-scroll-offset="10%"
            data-scroll
            data-scroll-speed="-2"
          >
            <div class="inner">
              <h4>What does <span class="cbd">CBD</span> do?</h4>
              <p>
                Increasingly countries worldwide are researching the benefits of
                <span class="cbd">CBD</span>. Fortunately – and as a retailer of
                supplements – we operate in a heavily regulated industry that
                leaves no room for misleading promises. Part of the legislation
                we abide by means that we're only able to make health claims for
                products that have been approved within the EU register.
              </p>
              <p>
                Being an ingredient that's new to the UK,
                <span class="cbd">CBD</span> is yet to be approved, so we cannot
                relay details to the public about relevant scientific research.
                We do – however – encourage you to go online and research for
                yourself. There's plenty of information out there that explains
                why <span class="cbd">CBD</span> has gained such huge traction
                in the last few months.
              </p>
            </div>
          </div>

      </div>

    </div>
  </section>
</template>

<script>
export default {
};
</script>

<style lang="scss" scoped></style>
