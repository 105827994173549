<template>
  <div class="page-transition" v-if="show">
    <div class="wipe wipe-1"></div>
    <div class="wipe wipe-2">
      <div class="marquee">
        <div class="marquee__inner" aria-hidden="true">
          <span>Loading</span>
          <span>Loading</span>
          <span>Loading</span>
          <span>Loading</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show"],
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.page-transition {
  position: fixed;
  top: 0;
  left: -50vw;
  height: 100vh;
  width: 200vw;
  z-index: 9999;
  .wipe {
    position: absolute;
    @include FlexCentered();
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    animation: slideUpSkew 0.5s ease both;
    .marquee {
      position: relative;
      overflow: hidden;
      --offset: 20vw;
      --move-initial: calc(-25% + var(--offset));
      --move-final: calc(-50% + var(--offset));
      .marquee__inner {
        width: fit-content;
        display: flex;
        position: relative;
        transform: translate3d(var(--move-initial), 0, 0);
        animation: marquee 5s linear infinite;
        animation-play-state: playing;
      }
      span {
        opacity: 1;
        font-family: $h-font;
        font-size: 10vw;
        font-weight: 900;
        padding: 0 2vw;
        transform: skewX(-10deg);
      }
    }
    &.wipe-1 {
      background-color: $green;
    }
    &.wipe-2 {
      background-color: $white;
      color:$black;
      animation-delay: 0.15s;
    }
  }
  &.enter {
    .wipe {
      animation: slideOutUpSkew 0.5s ease-out both;
      &.wipe-1 {
        animation-delay: 0.3s;
      }
      &.wipe-2 {
        animation-delay: 0.15s;
      }
    }
  }
}
</style>
