//Scroll
import LocomotiveScroll from "locomotive-scroll";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default {
  data() {
    return {
      lms: null,
    };
  },
  mounted() {
    this.lms = new LocomotiveScroll({
      el: document.querySelector("#app"),
      offset: [0, "30%"],
      smooth: true,
      lerp: 0.09,
      delay: 0,
      repeat: true,
      getSpeed: true,
      getDirection: true,
      smartphone: {
        smooth: true,
        direction: true,
        horizontalGesture: true,
      },
      tablet: {
        smooth: true,
        direction: true,
        horizontalGesture: true,
      },
      reloadOnContextChange: true,
    });
    // this.lms.on("scroll", ScrollTrigger.update);
    // ScrollTrigger.scrollerProxy("#app", {
    //   scrollTop(value) {
    //     return arguments.length
    //       ? this.lms.scrollTo(value, 0, 0)
    //       : this.lms.scroll.instance.scroll.y;
    //   }, // we don't have to define a scrollLeft because we're only scrolling vertically.
    //   getBoundingClientRect() {
    //     return {
    //       top: 0,
    //       left: 0,
    //       width: window.innerWidth,
    //       height: window.innerHeight,
    //     };
    //   },
    //   // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
    //   pinType: document.querySelector("#app").style.transform
    //     ? "transform"
    //     : "fixed",
    // });
  },
  methods: {
    initLms() {
      this.lms.update();
      // this.lms.on("scroll", (e) => {

      // });
      // ScrollTrigger.create({
      //   trigger: "#intro",
      //   scroller: "#app",
      //   start: "top+=100",
      //   end: "+=200",
      //   animation: gsap.to("#intro .inner .bg", { scale: 1.2 }),
      //   scrub: 2,
      //   markers: true,
      // });

      // ScrollTrigger.addEventListener("refresh", () => this.lms.update());
      // ScrollTrigger.refresh();
    },
  },
  watch: {
    $route(to, from) {
      if (to) {
        this.lms.stop();
      }
      if (from) {
        setTimeout(() => {
          this.lms.update();
          this.lms.start();
        }, 200);
      }
    },
  },
};