<template>
  <aside v-if="show" :class="{ hide: startHide }">
    <Close @close="closePopup" />
    <splide :options="options">
      <splide-slide>
        <ProductCard
          img="/img/prod1.png"
          title="Super Strength CBD Oil Drops"
          text="5% high potency cannabidiol oil, filter-clear and purified in easy-to-use peppermint flavoured oral drops."
          url="https://www.healthspan.co.uk/shop/super-strength-cbd-oil-drops-480mg/10ml-bottle/"
        />
      </splide-slide>
      <splide-slide>
        <ProductCard
          img="/img/prod2.png"
          title="CBD Balm"
          text="A soothing, fragrant balm infused with 180mg cannabidiol, essential oils, and relaxing lavender."
          url="https://www.healthspan.co.uk/shop/cbd-balm/30g/"
        />
      </splide-slide>
      <splide-slide>
        <ProductCard
          img="/img/prod3.png"
          title="Opti-CBD"
          text="Optimal absorption NovaSOL® CBD that acts 16x faster than standard CBD."
          url="https://www.healthspan.co.uk/shop/opti-cbd/30-capsules/"
        />
      </splide-slide>
    </splide>
  </aside>
</template>

<script>
import Close from "@/components/util/Close.vue";
import ProductCard from "@/components/ProductCard.vue";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";

export default {
  components: {
    Close,
    ProductCard,
  },
  data() {
    return {
      options: {
        drag: true,
        width: 300,
        gap: "5rem",
        pagination: false,
        waitForTransition: false,
        rewind: true,
        easing: "cubic-bezier(0.895, 0.03, 0.685, 0.22)",
      },
      show: false,
      startHide: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.show = true;
    }, 500);
  },
  methods: {
    closePopup() {
      this.startHide = true;
      setTimeout(() => {
        this.show = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
aside {
  position: fixed;
  left: 0.5rem;
  bottom: 0.5rem;
  border-radius: $border-rad;
  animation: slideUp 1s 1s cubic-bezier(0.23, 1, 0.32, 1) both;
  z-index: 9;
  @media (min-width:1920px) {
    bottom:3rem;
    left:1rem;
  }
  @media (max-width:$break-l) {
    bottom:60px;
  }
  &.hide {
    animation: slideOutDown 0.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) both;
  }
  .close {
    z-index: 1;
  }
  .splide__track {
    border-radius: $border-rad;
    overflow: hidden;
  }
  .splide__arrow {
    width: 2rem;
    height: 2rem;
    background-color: $secondary;
    transform: translateY(-50%) scale3d(1, 1, 1);
    transition: transform 0.25s ease;
    opacity: 1;
    &:hover {
      opacity: 1;
      transform: translateY(-50%) scale3d(1.1, 1.1, 1.1);
    }
    svg {
      fill: $white;
    }
    &.splide__arrow--next {
      right: -0.5rem;
    }
    &.splide__arrow--prev {
      left: -0.5rem;
    }
  }
}
</style>
